<template>
  <div>
    <typed-doc-picker
      :selected.sync="selected"
      multiple
    />
  </div>
</template>
<script>
export default {
  components: {
    TypedDocPicker: () => import('@/components/typed-doc-picker')
  },
  data () {
    return {
      selected: null
    }
  },
  watch: {
    selected (n) {
      console.log(n)
    }
  }
}
</script>
